<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Condições Comerciais</h3>
            </v-col>
            <v-col 
                cols="2" 
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            @click="showDialogCreateEdit(null)"
                        >
                            Novo
                        </v-btn>
                    </template>
                    <span>Novo</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Pesquisa Rápida"
                        append-outer-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="Nenhum registro encontrado"
                    :footer-props="{
                        'items-per-page-text':'Registros por Página',
                        'items-per-page-all-text':'Todos',
                        pageText: '{0}-{1} de {2}'
                    }"
                >
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.idOrcamentoCondicaoComercial" 
                            :showButtons="{
                                edit: true,
                                delete: true,
                            }"
                            @customActionEdit="showDialogCreateEdit(item)"
                            @confirmDelete="confirmDelete"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        
		<v-dialog
			v-model="showDialogItem"
			transition="dialog-top-transition"
			persistent
			width="80%"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                    {{ orcamentoCondicaoComercialRequest.id == 0 ? 'Nova condição comercial' : 'Edição' }}
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
						
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Item</label>
							<v-text-field
								v-model="orcamentoCondicaoComercialRequest.item"
                                :rules=[validations.required]
								outlined
							>
							</v-text-field>
						</v-col>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
                            <label>Descrição</label>
                            <v-textarea
                                v-model="orcamentoCondicaoComercialRequest.descricao"
                                :rules=[validations.required]
                                single-line
                                rows="6"
                                row-height="50"
                                outlined
                            >
                            </v-textarea>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 btn-plus dialogButtonCancel"
							outlined
							@click="showDialogItem = false;"
						>
							Cancelar
						</v-btn>

						<v-btn
							class="mx-2 btn-plus"
							outlined
							@click="save()"
						>
							Confirmar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList,
            ActionDialog
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "idOrcamentoCondicaoComercial", sortable: true, size: "5%" },
                { text: "Item", value: "item", sortable: true, width: "20%" },
                { text: "Descrição", value: "descricao", sortable: true, width: "60%" },
                { text: "Ações", value: "action", sortable: false, align: "center", width: "15%" }
            ],

            options: { rowsPerPage: 10, page: 1 },
            
            showDialogItem: false,
            listCondicaoComercial: [],

            orcamentoCondicaoComercialRequest: {
                id: 0,
                item: '',
                descricao: ''
            },
            
            validations: {
                required: required,
            },
        }),

        computed: {

            filteredSearch: function() {
                return this.listCondicaoComercial.filter((filterCondicaoComercial) => {
                    
                    const filter = this.filter.fastSearch.toLowerCase();

                    var item = filterCondicaoComercial.item.toLowerCase().match(filter)
                    var descricao = filterCondicaoComercial.descricao.toLowerCase().match(filter)
                    var idOrcamentoCondicaoComercial = filterCondicaoComercial.idOrcamentoCondicaoComercial.toString().match(filter);

                    if(item != null) { return item; } 
                    else if(descricao != null) { return descricao; } 
                    else { return idOrcamentoCondicaoComercial; }
                });
            }
        },

        methods: {
            
            async getRegisters() {

                this.listCondicaoComercial = await this.$store.dispatch("orcamentoModule/ListCondicaoComercial");
            },

            showDialogCreateEdit(item) {
                if (item && item != null) {
                    this.orcamentoCondicaoComercialRequest = {
                        id: item.idOrcamentoCondicaoComercial,
                        item: item.item,
                        descricao: item.descricao
                    }
                }
                else {
                    this.orcamentoCondicaoComercialRequest = {
                        id: 0,
                        item: null,
                        descricao: null
                    }
                }

                this.showDialogItem = true;
            },

            confirmDelete(id) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: 'Você irá EXCLUIR esse Item, confirma sua decisão?',
                    methodConfirm: this.delete,
                    params: id
                };
            },

            async delete(id) {

                this.showLoading();

                const result = await this.$store.dispatch("orcamentoModule/DeleteCondicaoComercial", id);

                if (result.success) {
                    this.showToast("success", "Sucesso!", result.message);
                }
                else {
                    this.showToast("error", "Erro!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            async save() {
                if (this.orcamentoCondicaoComercialRequest.item == null || 
                    this.orcamentoCondicaoComercialRequest.item == undefined || 
                    this.orcamentoCondicaoComercialRequest.item.toString().trim() == '') {
                    this.showToast("error", "Erro!", "Campo Item é obrigatório");
                }
                else if (this.orcamentoCondicaoComercialRequest.descricao == null || 
                    this.orcamentoCondicaoComercialRequest.descricao == undefined || 
                    this.orcamentoCondicaoComercialRequest.descricao.toString().trim() == '') {
                    this.showToast("error", "Erro!", "Campo Descrição é obrigatório");
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    const result = await this.$store.dispatch("orcamentoModule/CreateUpdateCondicaoComercial", this.orcamentoCondicaoComercialRequest);

                    if (result.success === true) {
                        this.showToast("success", "Sucesso!", result.message);
                        await this.getRegisters();
                        this.showDialogItem = false;
                    }
                    else {
                        this.showToast("error", "Aviso!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>
